
























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import DistributeUnassignedAmountCardViewModel
  from '@/vue-app/view-models/components/goals-dashboard/distribute-unassigned-amount-card-view-model';

@Component({
  name: 'DistributeUnassignedAmountCard',
  components: {
    LoadingDataCard,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
    DistributeBalanceUnallocatedModal: () => import('@/vue-app/components/goals-dashboard/unallocated-balance/DistributeBalanceUnallocatedModal.vue'),
  },
})
export default class DistributeUnassignedAmountCard extends Vue {
  // eslint-disable-next-line max-len
  distribute_unassigned_amount_model = Vue.observable(new DistributeUnassignedAmountCardViewModel());

  @Watch('distribute_unassigned_amount_model.customer_contracted_products_state.loaded')
  handleCustomerContractedProductsChange(loaded: boolean) {
    if (loaded) {
      this.distribute_unassigned_amount_model.initialize();
    }
  }

  created() {
    this.distribute_unassigned_amount_model.initialize();
  }
}
