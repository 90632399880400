import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetCustomerInvestorGoalBalanceQuery from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import {
  GetCustomerKuspitPositionQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';

// Domain
import {
  CustomerContractedInvestmentProductsStateManager,
} from '@/modules/flagship/customer-contracted-investment-products/domain/state/customer-contracted-investment-products-state-manager';
import {
  InvestmentProductsEntity,
} from '@/modules/flagship/catalogs/investment-products/domain/entities/investment-products-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type Product = {
  id: string;
  product: string;
  name: string;
  title: string;
  unassigned_amount: number;
  has_goal_balance: boolean;
  img: string;
  alt: string;
  max_width: number;
  class: string;
  has_active_goals: boolean;
}

type AssociatedProduct = {
  name: string;
  associated_product_id: string;
}

export default class DistributeUnassignedAmountCardViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_QUERY)
  private readonly get_customer_kuspit_position_query!: GetCustomerKuspitPositionQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_STATE_MANAGER)
  private readonly customer_contracted_investment_products_state_manager!:
    CustomerContractedInvestmentProductsStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.distribute-unassigned-amount-card';

  private readonly customer_id = sessionStorage.getItem('user_id');

  is_loading = false;

  associated_product_ids: Array<AssociatedProduct> = [];

  window_number = 0;

  max_number_windows = 1;

  show_distribute_unassigned_amount = false;

  selected_product: AssociatedProduct = {
    associated_product_id: '',
    name: '',
  }

  customer_contracted_products_state = this
    .customer_contracted_investment_products_state_manager.state;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  products: Array<Product> = [
    {
      id: '',
      product: 'sowos_pocket',
      name: 'SOWOS Pocket',
      title: this.translate('sowos_pocket'),
      unassigned_amount: 0,
      has_goal_balance: false,
      img: 'ahorro_kuspit.svg',
      alt: this.translate('alts.pocket_image'),
      max_width: 25,
      class: 'secondary--text',
      has_active_goals: false,
    },
    {
      id: '',
      product: 'sowos_wealth',
      name: 'SOWOS Wealth',
      title: this.translate('sowos_wealth'),
      unassigned_amount: 0,
      has_goal_balance: false,
      img: 'inversion_allianz.svg',
      alt: this.translate('alts.wealth_image'),
      max_width: 30,
      class: 'accent--text',
      has_active_goals: false,
    },
  ];

  initialize = async () => {
    this.is_loading = true;
    await this.loadInvestmentProducts();
    await this.checkIfUserHasAtLeastOneLinkedGoal();
    this.is_loading = false;
  }

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      investment_products.forEach((item: InvestmentProductsEntity) => {
        this.associated_product_ids.push({
          name: item.name,
          associated_product_id: item.id,
        });
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  checkIfUserHasAtLeastOneLinkedGoal = async () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const product of this.associated_product_ids) {
      this.loadCustomerInvestorGoalBalance(product.associated_product_id, product.name);
    }
  }

  setUnassignedBalance = (
    product_id: string, product: string, unassigned_balance: number, has_active_goals: boolean,
  ) => {
    const item_product = this.products.find((item) => item.product === product);
    if (item_product) {
      item_product.id = product_id;
      item_product.unassigned_amount = unassigned_balance;
      item_product.has_goal_balance = true;
      item_product.has_active_goals = has_active_goals;
    }
  }

  loadKuspitPosition = async () => {
    let kuspit_position = 0;
    try {
      const kuspit_position_data = await this.get_customer_kuspit_position_query.execute();

      if (kuspit_position_data.has_funds) {
        kuspit_position = Math.round(parseFloat(kuspit_position_data.position) * 100) / 100;
      }
      return kuspit_position;
    } catch {
      return kuspit_position;
    }
  }

  loadAllianzPosition = async () => {
    let position = 0;
    try {
      const account_reports = await this.search_allianz_account_report_query
        .execute(this.customer_id);
      const pesos_account_report = account_reports.find(
        (account_report) => account_report.found_code === 'SWSRFP',
      );
      if (pesos_account_report) {
        position = pesos_account_report.final_balance_mxn || 0;
      }
      return position;
    } catch {
      return position;
    }
  }

  loadBalanceFromPosition = async (product: string) => {
    if (product === 'sowos_pocket' && this.customer_contracted_products_state.item
      .has_sowos_pocket_contracted) {
      const position = await this.loadKuspitPosition();
      this.setUnassignedBalance('', product, position, false);
    }

    if (product === 'sowos_wealth' && this.customer_contracted_products_state.item
      .has_sowos_wealth_contracted) {
      const position = await this.loadAllianzPosition();
      this.setUnassignedBalance('', product, position, false);
    }
  }

  loadCustomerInvestorGoalBalance = async (associated_product_id: string, product: string) => {
    try {
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query
        .execute({ investment_product_id: associated_product_id });
      this.setUnassignedBalance(associated_product_id, product, unassigned_balance, true);
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      } else {
        this.loadBalanceFromPosition(product);
      }
    }
  }

  distribute = (index: number) => {
    this.selected_product.associated_product_id = this.products[index].id;
    this.selected_product.name = this.products[index].name;
    this.show_distribute_unassigned_amount = true;
  }
}
